
import { updateSearchBarOptions, searchBlockPickerOpen, searchBlockPickerClose } from "./search-bar.js"
import { submit as dynamicSearchSubmit } from "./dynamic-search.js"
import { default as Mustache } from "./mustache.js"
import { breakpoints, lockout } from "./utilities.js";
import "./dayjs.min.js"

const dayJsIsToday = (date) => {
	const mask = "YYYY-MM-DD";
	return dayjs().format(mask) === dayjs(date).format(mask)
}
const dayJsRange = (from, to, format) => {
	const list = [];
	let current = dayjs(from);
	while (current.isBefore(dayjs(to))) {
		list.push(format ? current.format(format) : current.format())
		current = current.add(1, 'day')
	}
	return list
}
dayjs.Ls.en.weekStart = 1;


// Global to all date pickers
var availableDates = [];

function elementIsInDatePicker(element) {

	if (element.hasClass('js-folks-selector-destination-wrapper')) {
		return false;
	}

	if (
		element.parents('.c-date-picker').length
		|| element.closest('.js-datepicker-placeholder').length
		|| element.hasClass('js-datepicker-input')
		|| element.hasClass('js-datepicker-trigger')
		|| element.hasClass('js-datepicker-wrapper')
		|| element.hasClass('js-date-picker-day')
		|| element.hasClass('js-date-picker-previous-month')
		|| element.hasClass('js-date-picker-next-month')
		|| element.hasClass('js-tabs-search-block')
		|| element.hasClass('c-date-picker__radio-text')
		|| element.hasClass('c-date-picker__arrow-icon')
		|| element[0].classList.contains('c-date-picker__arrow-icon')
	) {
		return true;
	}

	return false;
}

/**
 * The main DatePicker Object
 * @param {any} container jQuery selector for the wrapper
 */
function DatePicker(container) {

	var formContainer = container.closest('form');
	var folksSelectorDurationInputValue = formContainer.find('.js-folks-selector-duration').val();
	var firstMonth = new Date();
	var secondMonth = dayjs(firstMonth).add(1, 'month');
	var holidayStateDate = getSelectedDateValue();
	var lengthOfHoliday = parseInt(folksSelectorDurationInputValue) || 7;
	var activeDates = [];
	var availableMonths = [];
	var formInputsIdSuffix = generateGuid();
	var holidayLengthAnySelected = !folksSelectorDurationInputValue;
	var holidayLength7Selected = folksSelectorDurationInputValue && lengthOfHoliday === 7;
	var holidayLength14Selected = folksSelectorDurationInputValue && lengthOfHoliday === 14;
	var datepickerIsActive = false;
	var monthsHaveBeenSet = false;


	/*
	 * The date needs to be formatted so that JavaScript dates don't switch days and months
	 */
	function getSelectedDateValue() {
		var value = formContainer.find('.js-datepicker-input-date').val();
		if (!value) {
			return;
		}

		if (dayjs().isBefore(value) && !dayJsIsToday(value)) {
			return undefined;
		}

		// dd/mm/yyyy
		var splitDate = value.split('/');
		var formatted = splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2];
		return formatted;
	}

	function setData(isInitial) {
		if (holidayStateDate) {
			if (!holidayLengthAnySelected) {
				activeDates = dayJsRange(holidayStateDate, dayjs(holidayStateDate).add(lengthOfHoliday, 'days'));
			} else {
				activeDates = [];
			}
			// only do on first open
			if (isInitial) {
				firstMonth = holidayStateDate;
				secondMonth = dayjs(holidayStateDate).add(1, 'month').startOf('month');
			}
		}

		calculateAvailableDates();

		var viewData = {
			firstMonthTitle: dayjs(firstMonth).format('MMMM YYYY'),
			daysInMonthOne: getDaysInMonth(dayjs(firstMonth).startOf('month'), dayjs(firstMonth).endOf('month')),
			secondMonthTitle: dayjs(secondMonth).format('MMMM YYYY'),
			daysInMonthTwo: getDaysInMonth(dayjs(secondMonth).startOf('month'), dayjs(secondMonth).endOf('month')),
			showMonthTwo: shouldShowMonthTwo(),
			availableMonths: availableMonths,
			formInputsIdSuffix: formInputsIdSuffix,
			holidayLengthAnySelected: holidayLengthAnySelected,
			holidayLength7Selected: holidayLength7Selected,
			holidayLength14Selected: holidayLength14Selected,
			datepickerIsActive: datepickerIsActive,
			flexibleDatesIsChecked: getValueOfFlexibleDates(),
			searchFlexibleDatesExpansionNumber: 3
		};

		render(viewData);
	}

	function shouldShowMonthTwo() {
		var lastMonth = availableMonths[availableMonths.length - 1];
		if (!lastMonth) {
			return true;
		}
		if (dayjs(secondMonth).isAfter(lastMonth.value, 'month')) {
			return false;
		}

		return true;
	}

	function calculateAvailableDates() {
		var availableMonthsToSet = [];
		try {
			var seasonMonths = getSeasonMonths();

			if (!monthsHaveBeenSet) {
				var startMonth = holidayStateDate ? holidayStateDate : seasonMonths[0];
				firstMonth = startMonth;
				secondMonth = getSecondMonth(firstMonth);
			}

			for (var i = 0; i < seasonMonths.length; i++) {

				var monthDate = dayjs(seasonMonths[i]).toDate();
				availableMonthsToSet.push({
					text: dayjs(monthDate).format('MMMM YYYY'),
					value: monthDate,
					selected: dayjs(monthDate).isSame(firstMonth, 'month')
				});

			}
			availableMonths = availableMonthsToSet;
			monthsHaveBeenSet = true;
		} catch (e) {
			if (console !== 'undefined') {
				console.log(e);
			}
		}
	}

	function getSecondMonth(firstMonth) {

		var seasonMonths = getSeasonMonths();
		for (var i = 0, len = seasonMonths.length; i < len; i++) {

			var currentMonth = seasonMonths[i];

			if (dayjs(dayjs(firstMonth).startOf('month')).isSame(dayjs(currentMonth).startOf('month'))) {
				if (seasonMonths[i + 1]) {
					return dayjs(seasonMonths[i + 1]).startOf('month');
				}
			}
		}

		return dayjs(firstMonth).add(1, 'month').startOf('month').toDate();
	}

	function getSeasonMonths() {
		return JSON.parse($('.js-datepicker-season-months-json').html().replace(new RegExp('&quot;', 'g'), '"')).CurrentAndFutureSeasonMonths;
	}

	function openDatePicker(context) {
		// close any exist open ones
		closeDatePicker();

		// set scroll
		if (window.site.win.width() < breakpoints.tablet) {

			lockout.on();
			$('.l-nav').css('z-index', 10);
		}
		window.site.html.addClass('date-picker-open');
		searchBlockPickerOpen(context);

		// open the selected one
		context.find('.c-date-picker').addClass('is-active');
		datepickerIsActive = formContainer.find('.c-date-picker').hasClass('is-active');
		context.find('.js-datepicker-wrapper').addClass('is-active');
	}

	function closeDatePicker() {

		// set scroll
		if ($('.c-date-picker').hasClass('is-active')) {
			window.site.html.removeClass('date-picker-open');
			searchBlockPickerClose();

			if (window.site.win.width() < breakpoints.tablet) {
				lockout.off();
				$('.l-nav').css('z-index', 12);
			}
		}

		$('.c-date-picker').removeClass('is-active');
		datepickerIsActive = false;
		$('.js-datepicker-wrapper').removeClass('is-active');
	}

	function attachEventListeners() {

		var body = window.site.body;

		body.on('availableDatesLoaded', function(data) {
			availableDates = data.availableDates;
			setData();
		});

		body.on('datePickerOffClick', function(e) {
			if ($('.js-datepicker-wrapper').hasClass('is-active')
				&& $('.js-datepicker-wrapper.is-active').parents('.js-dynamic-search').length
				&& !e.context.hasClass('js-airport-picker-trigger')
				&& !e.context.hasClass('js-airport-picker-facade')
				&& !e.context.hasClass('js-folks-selector-destination')
				&& !e.context.hasClass('js-folks-selector-trigger')
				&& !e.context.hasClass('js-destination-picker-facade')) {
				dynamicSearchSubmit(false);
			}
			closeDatePicker(true);
		});


		body.on('closeDatePicker', function() {
			closeDatePicker();
		});

		body.on('datePickerInputTriggerClick', function(e) {
			openDatePicker($(e.context));
		});

		body.on('datePickerApplyClick', function() {
			if ($('.js-datepicker-wrapper').hasClass('is-active') && $('.js-datepicker-wrapper.is-active').parents('.js-dynamic-search').length) {
				dynamicSearchSubmit(false);
			}
			closeDatePicker(true);
		});

		body.on('datePickerDayClick', function(e) {
			if (!isElementInCurrentContainer(e.context)) return;
			handleDateSelected($(e.context));
		});

		body.on('datePickerMonthChange', function(e) {
			if (!isElementInCurrentContainer($(e.context))) return;
			firstMonth = dayjs($(e.context).val()).startOf('month').toDate();
			secondMonth = getSecondMonth(firstMonth);
			setData();
		});

		body.on('datePickerLengthChange', function(e) {
			if (!isElementInCurrentContainer($(e.context))) return;
			lengthOfHoliday = parseInt($(e.context).val());
			holidayLength7Selected = $(e.context).hasClass('js-date-picker-length-selector-7');
			holidayLength14Selected = $(e.context).hasClass('js-date-picker-length-selector-14');
			holidayLengthAnySelected = $(e.context).hasClass('js-date-picker-length-selector-any');
			formContainer.find('.js-folks-selector-duration').val(holidayLengthAnySelected ? '' : lengthOfHoliday).trigger('change');

			updateSearchBarOptions();
			setData();
			if (holidayStateDate) {
				setFacadeText();
			}
		});

		body.on('datePickerPreviousMonth', function(e) {
			if (!isElementInCurrentContainer(e.context)) return;
			var currentMonthSelectedIndex = container.find('.js-date-picker-month-selector option:selected').index();
			if (currentMonthSelectedIndex !== 0) {
				setSelectedIndexOfMonthSelect(currentMonthSelectedIndex - 1);
			}
		});

		body.on('datePickerNextMonth', function(e) {
			if (!isElementInCurrentContainer(e.context)) return;
			var currentMonthSelectedIndex = container.find('.js-date-picker-month-selector option:selected').index();
			setSelectedIndexOfMonthSelect(currentMonthSelectedIndex + 1);
		});

		body.on('datePickerFlexibleDatesChange', function(e) {
			if (!isElementInCurrentContainer(e.context)) return;
			formContainer.find('.js-datepicker-flexible-date-hidden').val(e.context.is(':checked') ? 'True' : 'False');
		});

	}

	function setSelectedIndexOfMonthSelect(index) {
		container.find('.js-date-picker-month-selector option').eq(index).prop('selected', true);
		container.find('.js-date-picker-month-selector').trigger('change');
	}

	function isElementInCurrentContainer(element) {
		return element.closest(container).length;
	}

	function getDaysInMonth(startDate, endDate) {

		const isMonday = dayjs(startDate).format('ddd') === 'Mon';
		const isWeekend = (date) => {
			const weekendDay = dayjs(date).format('ddd');
			return weekendDay === 'Sun' || weekendDay === 'Sat';
		}
		var daysInMonth = dayJsRange(startDate, endDate);
		var daysPossiblyInTheMonthBefore = isMonday ? [] : dayJsRange(dayjs(startDate).startOf('week'), dayjs(startDate));
		var possibleDays = daysPossiblyInTheMonthBefore.concat(daysInMonth);
		var formatted = possibleDays.map(function(date) {
			return {
				date: date,
				text: dayjs(date).isBefore(startDate) ? '' : dayjs(date).format('D'),
				isDisabled: !isDateAvailable(date),
				isActive: isActiveDate(date),
				isWeekend: isWeekend(date),
				isStartDate: dayjs(date).isSame(holidayStateDate),
				showButton: !dayjs(date).isBefore(startDate)
			};
		});

		// split down into array of arrays for mustache
		var final = [];
		while (formatted.length) {
			final.push(formatted.splice(0, 7));
		}

		return final;
	}

	function isDateAvailable(date) {

		if (dayjs(date).isBefore(dayjs()) && !dayJsIsToday(date)) {
			return false;
		}

		for (var i = 0, len = availableDates.length; i < len; i++) {
			if (dayjs(availableDates[i]).isSame(date, 'day')) {
				return true;
			}
		}

		return false;
	}

	function isActiveDate(date) {

		for (var i = 0, len = activeDates.length; i < len; i++) {
			if (dayjs(activeDates[i]).isSame(date, 'day')) {
				return true;
			}
		}

		return false;
	}

	function handleDateSelected(context) {

		if (context.hasClass('is-disabled')) {
			return;
		}

		var selectedStartDate = context.attr('data-date');
		holidayStateDate = selectedStartDate;
		setFacadeText();
		formContainer.find('.js-datepicker-input-date').val(dayjs(holidayStateDate).format('DD/MM/YYYY')).trigger('change');

		activeDates = dayJsRange(selectedStartDate, dayjs(selectedStartDate).add(lengthOfHoliday + 1, 'days'));
		setData();
	}

	function setFacadeText() {
		formContainer.find('.js-datepicker-input').val(generateFacadeText());
	}

	function generateFacadeText() {
		if (!holidayStateDate) {
			return '';
		}

		var returningDate;
		if (holidayLength7Selected) {
			returningDate = dayjs(holidayStateDate).add(7, 'day');
		} else if (holidayLength14Selected) {
			returningDate = dayjs(holidayStateDate).add(14, 'day');
		}
		if (!returningDate) {
			return dayjs(holidayStateDate).format('DD/MM/YYYY');
		}

		return dayjs(holidayStateDate).format('DD/MM/YYYY') + ' → ' + dayjs(returningDate).format('DD/MM/YYYY');
	}

	function generateGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	function getValueOfFlexibleDates() {
		return formContainer.find('.js-datepicker-flexible-date-hidden').val().toLowerCase() === 'true';
	}

	function render(viewData) {

		// save last active element
		var lastActiveElement = $(document.activeElement);

		var template = Mustache.render($('#datePickerTemplate').html(), viewData);
		container.empty().html(template);

		// if last active focus after render
		if (lastActiveElement.length && elementIsInDatePicker(lastActiveElement)) {
			setTimeout(function() {
				lastActiveElement.focus();
			}, 1000);
		}
	}

	setData(true);
	attachEventListeners();
	setFacadeText();
}

const init = () => {

	if (!$('.js-datepicker-placeholder').length) {
		return;
	}

	var datePickers = [];

	// init all date pickers
	$('.js-datepicker-placeholder').each(function() {
		datePickers.push(new DatePicker($(this)));
	});

	// init listeners so we only have one set and are not attaching listerners for each date picker on the page.
	var body = window.site.body;

	// off click to close
	body.on('click', function(e) {
		if (e.target && !elementIsInDatePicker($(e.target))) {
			body.trigger({
				type: "datePickerOffClick",
				context: $(e.target)
			});
		}
	});

	body.on('datePickerReInitAfterSearch', function() {
		// the reference to container is destroyed by the search clearing the HTML
		datePickers = null;
		datePickers = [];
		$('.js-datepicker-placeholder').each(function() {
			datePickers.push(new DatePicker($(this)));
		});
	});

	body.on('click keypress', '.js-datepicker-trigger', function(e) {
		if (e.type === 'click' || ((e.type === 'keypress') && e.which === 13)) {
			e.preventDefault();

			// if there are any current date pickers open we need to get the available for the new one
			if ($('.c-date-picker.is-active').length) {
				updateSearchBarOptions($(e.target).closest('form'));
			}

			// if datepicker already active close
			if ($(e.target).closest('form').find('.c-date-picker').hasClass('is-active')) {
				body.trigger({
					type: "datePickerOffClick",
					context: $(e.target)
				});
			} else { // open if not
				body.trigger({
					type: "datePickerInputTriggerClick",
					context: $(e.target).closest('form')
				});
			}


		}
	});

	body.on('click', '.js-date-picker-apply', function() {
		body.trigger({
			type: "datePickerApplyClick"
		});
	});

	body.on('click', '.js-date-picker-day', function() {
		body.trigger({
			type: "datePickerDayClick",
			context: $(this)
		});
	});

	body.on('change', '.js-date-picker-month-selector', function() {
		body.trigger({
			type: "datePickerMonthChange",
			context: $(this)
		});
	});

	body.on('change', '.js-date-picker-length-selector', function() {
		body.trigger({
			type: "datePickerLengthChange",
			context: $(this)
		});
	});

	body.on('click', '.js-date-picker-previous-month', function() {
		body.trigger({
			type: "datePickerPreviousMonth",
			context: $(this)
		});
	});

	body.on('click', '.js-date-picker-next-month', function() {
		body.trigger({
			type: "datePickerNextMonth",
			context: $(this)
		});
	});

	body.on('change', '.js-date-picker-flexible-dates-checkbox', function() {
		body.trigger({
			type: "datePickerFlexibleDatesChange",
			context: $(this)
		});
	});

	/* Do not trigger keyboard popup on iOS*/
	body.on('mousedown', '.js-folks-selector-departing', function(e) {
		e.preventDefault();
	});

}

export default init;
